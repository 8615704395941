<template>
  <div
    class="destination-field"
  >
    <div class="destination-field__inner">
      <city-search
        :hide-double="hideDouble"
        :placeholder="valText"
        :is-valid="isValid"
        :value="val"
        @input="setDestination"
      />
    </div>

    <div
      role="button"
      class="destination-field__icon"
      @click="openDestinationModal"
    >
      <esmp-icon
        name="pointer"
      />
    </div>

    <esmp-modal
      v-model="showDestinationModal"
      title="Населенный пункт"
      :width="800"
      :footer-hide="true"
      cancel-text="Закрыть"
      class-name="destination-modal"
    >
      <destination-modal-fieldset
        v-if="showDestinationModal"
        @close-modal="showDestinationModal = false"
        :value="value"
        @input="setValue"
      />
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';

import CitySearch from './CitySearch.vue';
import DestinationModalFieldset from './DestinationModalFieldset.vue';
import getDestinationCity from '../../helpers/getDestinationCity';
import { getRegionCode, getAreaCode } from '../../helpers/getDestinationCode';

export default {
  name: 'DestinationField',
  components: {
    CitySearch,
    DestinationModalFieldset,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Место назначения',
    },
    value: {
      type: Object,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    hideDouble: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDestinationModal: false,
    };
  },
  computed: {
    val() {
      if (this.value && this.value.code) {
        return this.value;
      }
      return getDestinationCity(this.value);
    },
    valText() {
      if (this.val) return this.val.name;

      return this.placeholder;
    },
  },
  methods: {
    ...mapActions('businessTrip2', [
      'getRegion',
      'getArea',
      'updateExpensesCity',
      'updateTransit',
    ]),
    openDestinationModal() {
      this.showDestinationModal = true;
    },
    async setDestination(citySearchValue) {
      if (isEmpty(this.val) && isEmpty(citySearchValue)) {
        this.$emit('input');
        return;
      }
      if (!isEmpty(this.val)
          && !isEmpty(citySearchValue)
          && citySearchValue.code === this.val.code
      ) {
        return;
      }

      const value = {
        id: this.value?.id || null,
        region: null,
        area: null,
        city: null,
        settlement: null,
      };

      if (!isEmpty(citySearchValue)) {
        // если город-регион
        if (citySearchValue.isRegion) {
          value.region = citySearchValue;
        } else if (citySearchValue.code) {
          const regionCode = getRegionCode(citySearchValue);
          const areaCode = getAreaCode(citySearchValue);

          value.region = await this.getRegion(regionCode);

          // если город-район
          if (citySearchValue.code === areaCode) {
            value.area = citySearchValue;
          } else {
            value.city = citySearchValue;
            if (areaCode !== regionCode) {
              value.area = await this.getArea({ regionCode, areaCode });
            }
          }
        }
      }

      this.$emit('input', value);
    },
    setValue(value) {
      this.$emit('input', value);
    },
  },
  watch: {
    value() {
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss" scoped>
.destination-field {
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
  &__inner {
    flex-basis: calc(100% - 40px);
  }
  &__icon {
    display: flex;
    flex-basis: 30px;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border: 1px solid $color-primary-1-day;
    color: $color-primary-1-day;
    border-radius: 50%;
    background: rgba(16, 24, 40, 0.05);
    ::v-deep .esmp-svg-icon {
      height: 20px;
      width: 20px;
    }
  }
}
</style>
